import { Auth, Comcom, Node, Sample, Stage, Step, User } from '@api/types';
import { API_HOST } from '@constants';
import { Http, Classroom, Notification } from '@types';
import { getFormData } from './helpers';
import { instance, instanceWOT } from './provider';
import { Survey } from './types/survey';
import axios, { AxiosRequestConfig } from 'axios';
import { TUserDataWithImpUid } from '@online/types';

export const Api = {
  invitation: {
    getUserDetail: async (code: string, config?: AxiosRequestConfig) => {
      return instanceWOT.get<Http.Response.TGetInvitedUserData>(
        '/auth/validcode/invitation/',
        {
          params: { validation_code: code },
          ...config,
        },
      );
    },
  },
  /**************************************
   *! AUTH
   *************************************/
  course: {
    getNextRoundStartTime: async (subjectId = 13) => {
      return instanceWOT.get<Http.Response.TGetNextRoundDeadline>(
        '/api-stages/roundtime/',
        {
          params: {
            subject_id: subjectId,
          },
        },
      );
    },
  },
  /**************************************
   *! AUTH
   *************************************/
  // #region
  auth: {
    postToken: (payload: Http.Request.ILogin, config?: AxiosRequestConfig) => {
      return instance.post<{ token: string }>(
        '/token/',
        getFormData({ ...payload }),
        config,
      );
    },
    postTokenKDC: (
      payload: Http.Request.ILogin,
      config?: AxiosRequestConfig,
    ) => {
      return instance.post<{ token: string }>(
        '/token-kdc/',
        getFormData({ ...payload }),
        config,
      );
    },
    refreshToken: (payload: Auth.TRefreshToken) => {
      let formData = new FormData();
      formData.append('token', payload.token);
      if (
        payload.localSchoolId !== null &&
        payload.localSchoolId !== undefined
      ) {
        formData.append('local_school_id', payload.localSchoolId.toString());
      }
      return instance.post<{ token: string }>('/refresh-token/', formData);
    },
    login: (payload: Http.Request.ILogin, config?: AxiosRequestConfig) => {
      return instance.post<Http.Response.TLogin>(
        '/login/',
        getFormData({ ...payload }),
        config,
      );
    },
  },
  // #endregion
  /**************************************
   *! STEP
   *************************************/
  // #region
  step: {
    fetchList: async () => {}, // NOTE: 안쓰나? swagger 에만 있음.
    postUpdateUserCode: (
      slug: string,
      payload: Step.TUpdateCode,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('code_data', payload.code);
      formData.append('block_id', payload.blockId);
      formData.append('block_order', payload.blockOrder.toString());
      formData.append('node_version_id', payload.nodeVersionId.toString());

      return instance.post(
        `/api-steps/updateUserCodeV2/${slug}/`,
        formData,
        config,
      );
    },
    // NOTE: 왜 퀴즈만 searchparam 으로 되어있지.
    postUpdateUserQuiz: (
      slug: string,
      payload: Step.TUpdateQuiz,
      config?: AxiosRequestConfig,
    ) => {
      let params = new URLSearchParams();
      params.append('quiz_data', payload.answer);
      params.append('block_id', payload.blockId);
      params.append('node_version_id', payload.nodeVersionId.toString());
      return instance.post(
        `/api-steps/updateUserQuizV2/${slug}/`,
        params,
        config,
      );
    },
    postUpdateUserVimeo: (
      slug: string,
      params: { blockId: string; nodeVersionId: number },
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('vimeo_data', 'watched');
      formData.append('block_id', params.blockId);
      formData.append('node_version_id', params.nodeVersionId.toString());
      return instance.post(
        `/api-steps/updateUserVimeoV2/${slug}/`,
        formData,
        config,
      );
    },
    /**
     * Step의 진행상태 업데이트 API
     * @param nodeVersionId
     * @param stepId
     * @param order
     * @param config axios config
     * @returns
     */
    postUpdateUserStepCompt: (
      nodeVersionId: number,
      stepId: number,
      order: number,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('node_version_id', nodeVersionId.toString());
      formData.append('step_id', stepId.toString());
      formData.append('order', order.toString());

      return instance.post('/api-steps/updateUserStepCompt/', formData, config);
    },

    postUpdateUserProjKdc: (nodeVersionId: number, userIp: number) => {
      let formData = new FormData();
      formData.append('node_version_id', nodeVersionId.toString());
      formData.append('user_ip', userIp.toString());
      return instance.post(`api-steps/updateUserProjKdc/`, formData);
    },
    /**
     * 커널 새로고침 3회 이상일 때 CS 누적
     */
    postUpdateStepComptQueue: (courseId: string, nodeVersionId: string, stepId: string, retry_count: string, wait_time: string) => {
      let formData = new FormData();
      formData.append('course_id', courseId);
      formData.append('node_version_id', nodeVersionId);
      formData.append('step_id', stepId);
      formData.append('retry_count', retry_count);
      formData.append('wait_time', wait_time);
      return instance.post(`api-nodes/stepcompt-queue`, formData);
    },
  },
  // #endregion
  /**************************************
   *! NODE
   *************************************/
  // #region
  node: {
    postUpdateUserProj: () => {},
    fetchTodayV1: () => {
      return instance.get<TCourseAndNodeV1>(`/api-nodes/v1/today/`);
    },
    fetchDetailV1: (nodeId: number | string, stageId: number | string) => {
      let params = new URLSearchParams();
      params.append('stage_id', stageId.toString());
      return instance.get<Node.TNodeDataV1>(`/api-nodes/v1/${nodeId}`, {
        params,
      });
    },
    fetchRubricV1: (nodeId: number) => {
      return instance.get(`/api-nodes/v1/${nodeId}/rubrics/`);
    },
    /*아이코랜드 접속을 위한 valid code 발급*/
    postValidCode() {
      return instance
        .post<{ valid_code: string; exp_date: string }>(`/auth/validcode/`)
        .then(({ data }) => data);
    },
    fetchTodayV2: () => {
      return instance.get(`/api-nodes/v2/today/`);
    },
    fetchDetailV2: (nodeVersionId: number | string, guide?: boolean) => {
      const urlString = guide
        ? `/api-nodes/v2/${nodeVersionId}?guide=true`
        : `/api-nodes/v2/${nodeVersionId}`;
      console.log(urlString);
      return instance.get<Node.TNodeData>(urlString);
      // return instance.get<Node.TNodeData>(`/api-nodes/v2/${nodeVersionId}`);
    },

    fetchGuideNodeDetail: (nodeVersionId: number | string) => {
      return instance.get<Node.TNodeData>(
        `/api-nodes/v2/${nodeVersionId}?guide=true`,
      );
    },

    fetchRubricV2: (nodeVersionId: number) => {
      return instance.get(`/api-nodes/v2/${nodeVersionId}rubric/`);
    },
    fetchWainNP: (pageNumber: number) => {
      let params = new URLSearchParams();
      params.append('page', pageNumber.toString());
      return instance.get<TWaitNPNodeList>(`/api-nodes/v1/waitnp/`, { params });
    },
  },
  // #endregion
  /**************************************
   *! NODE PROGRESS
   *************************************/
  // #region
  nodeProgress: {
    fetchProgress: (stageId: number) => {
      let params = new URLSearchParams();
      params.append('stage_id', stageId.toString());
      return instance.get<TCourseAndNodeV1>(`/api-node-progrs/`, { params });
    },

    postActiveUserNodeProgrsV2: (
      nodeId: number,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('node_progrs_id', nodeId.toString());
      return instance.post(
        `/api-node-progrs/activeUserNodeProgrsV2/`,
        formData,
        config,
      );
    },

    /**
     * (LMS용) Node 진행율 업데이트 API
     * @param nodeProgressId node_progrs.id
     * @param projectUrl 제출할 프로젝트 url (Github repo)
     * @param config axios config
     * @returns Axios Promise
     */
    // postUpdateUserNodeProgrsV2: (
    //   nodeProgressId: number,
    //   projectUrl: string,
    //   userIp: string,
    //   config?: AxiosRequestConfig,
    // ) => {
    //   let params = new URLSearchParams();
    //   params.append('node_progrs_id', nodeProgressId.toString());
    //   params.append('proj_url', projectUrl);
    //   params.append('user_ip', userIp);
    //   return instance.post(
    //     `/api-node-progrs/updateUserNodeProgrsV2/`,
    //     params,
    //     config,
    //   );
    // },

    /**
     * (LMS용) Node 진행율 업데이트 API
     * @param nodeProgressId node_progrs.id
     * @param projectUrl 제출할 프로젝트 url (Github repo)
     * @param projectFile 제출할 프로젝트 file (Uploaded file)
     * @param config axios config
     * @returns Axios Promise
     */
    postUpdateUserNodeProgrsV2: (
      nodeProgressId: number,
      projectUrl: string,
      projectFile: File | null,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('node_progrs_id', nodeProgressId.toString());

      if (projectUrl !== '') {
        formData.append('proj_url', projectUrl);
      }
      if (projectFile) {
        formData.append('project_file', projectFile);
      }
      return instance.post(
        `/api-node-progrs/updateUserNodeProgrsV2/`,
        formData,
        {
          ...config,
          headers: {
            ...config?.headers,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },

    /**
     * (KDC용) Node 진행율 업데이트 API
     * @param nodeProgressId node_progrs.id (Coach나 Editor는 null) //REVIEW: 왜 coach editor는 null인지?
     * @param config axios config
     * @returns Axios Promise
     */
    postUpdateUserNodeProgrsKdc: (
      nodeProgressId: number | null,
      projectUrl?: string,
      projectFile?: File | null,
      projectSubmitType?: string,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      if (nodeProgressId !== null) {
        formData.append('node_progrs_id', nodeProgressId.toString());
      }
      if (projectSubmitType) {
        formData.append('submit_type', projectSubmitType);
      }

      if (projectUrl && projectUrl !== '') {
        formData.append('submit_data', projectUrl);
      }
      if (projectFile) {
        formData.append('submit_data', projectFile);
      }
      return instance.post(
        `/api-node-progrs/updateUserNodeProgrsKdc/`,
        formData,
        {
          ...config,
          headers: {
            ...config?.headers,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },

    postActiveUserNodeProgrsKDC: (
      nodeProgrsId: number,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('node_progrs_id', nodeProgrsId.toString());
      return instance.post(
        `/api-node-progrs/activeUserNodeProgrsKdc/`,
        formData,
        config,
      );
    },
  },
  // #endregion
  /**************************************
   *! STAGE
   *************************************/
  // #region
  stage: {
    postActiveUserCourseProgrs: (
      courseProgressId: number,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('course_progrs_id', courseProgressId.toString());
      return instance.post(
        `/api-stages/activeUserCourseProgrs/`,
        formData,
        config,
      );
    },

    fetchClassroom: () => {
      return instance.get<TResponseClassroom>(`/api-stages/classroom/`);
    },

    fetchClassroomDetail: (classId: number) => {
      return instance.get<Classroom.TClassroomDetail>(
        `/api-stages/classroom/${classId}/`,
      );
    },

    fetchClassroomDetailMyNp: (classId: number, pageNumber?: number) => {
      let params = new URLSearchParams();
      if (pageNumber) {
        params.append('page', pageNumber.toString());
      }
      return instance.get<
        TPageResponse<{
          comment?: string | null;

          id: number;
          node_schedule: TNodeInfo;
          progrs_pct: number;
          status: TNodeStatus;
          sum_score?: number | null;
          user_enrolments: TUserEnrolment;
          guide_nodeversion: TGuideNodeversion;
        }>
      >(`/api-stages/classroom/${classId}/mynp/?page=${pageNumber}`);
    },

    // NOTE: getNodeByStageIdAPI - 학생 나의 발자취
    fetchCompnp: (stageId: number, pageNumber: number) => {
      let params = new URLSearchParams();
      if (stageId !== 0) {
        params.append('stage_id', stageId.toString());
      }
      params.append('page', pageNumber.toString());
      return instance.get<TCompnpList>(`/api-stages/compnp/`, {
        params,
      });
    },
    fetchStagesV1Coach: (filter?: TNodeV1Filter) => {
      let params = new URLSearchParams();
      if (filter?.condition) {
        params.append('cond', filter?.condition);
      }
      if (filter?.stageId) {
        params.append('stage_id', filter?.stageId?.toString());
      }
      return instance.get<TCourseAndNodeV1>(`/api-evaluation/all/`, { params });
    },
    fetchStagesV1Editor: (filter?: TNodeV1Filter) => {
      let params = new URLSearchParams();
      if (filter?.condition) {
        params.append('cond', filter?.condition);
      }
      if (filter?.stageId) {
        params.append('stage_id', filter?.stageId?.toString());
      }
      return instance.get<TCourseAndNodeV1>(`/api-stages/v1/`, { params });
    },

    fetchStagesV2: () => {
      return instance.get<TCourseAndNodeV2>(`/api-stages/v2/`);
    },

    postCertiAcheivement: (
      courseProgressId: number,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('course_progrs_id', courseProgressId.toString());
      return instance.post('api-stages/getCertiAcheivement/', formData);
    },
    getAllCourses: (schoolId?: number, config?: AxiosRequestConfig) => {
      const someId = 12; // NOTE: 현재 12로 사용중. c
      return instanceWOT.get<Stage.TAllCourseResponse>(
        `api-stages/online/${someId}/`,
        config,
      );
    },
    fetchCourseDetailInfo: (
      courseId: number | string,
      slug: string,
      config?: AxiosRequestConfig,
    ) => {
      return instanceWOT.get<Stage.TCourseDetailResponse>(
        `/api-stages/online/${courseId.toString()}/${slug}/`,
      );
    },
  },
  // #endregion
  /**************************************
   *! USER
   *************************************/
  // #region
  user: {
    fetchUser: (id: number, config?: AxiosRequestConfig) => {
      return instance.get<User.TUser>(`/api-users/users/${id}/`, config);
    },
    /** cloud 사용 설정이 활성상태인지 비활성상태인지에 대한 조회 API */
    fetchUseCloud: (config?: AxiosRequestConfig) => {
      console.error('1103 fetchUseCloud', config);
      return instance.get<{
        /** cloud 사용 설정이 활성상태 여부 */
        use_cloud: boolean;
      }>(`/api-users/status/get_use_cloud/`, config);
    },

    fetchUserMarketingInfo: () => {
      return instance.get<User.TUserMarketing>('/api-users/profileKdc/');
    },

    privacy: {
      // 내일배움카드 정보조회
      fetchHasUserCheckedNBCardYn: () => {
        return instance.get<Http.Response.THasUserCheckedNbCardYn>(
          '/api-users/privacy/select-nb-card-yn/',
        );
      },
      // 내일배움카드 유/무 업데이트
      updateNBCard: (hasCard: boolean) => {
        const formData = new FormData();
        formData.append('nb_card_yn', hasCard === true ? 'True' : 'False');
        return instance.post('/api-users/privacy/update-nb-card-yn/', formData);
      },
    },

    fetchProfile: (config?: AxiosRequestConfig) => {
      return instance.get<User.TProfile>(`/api-users/profile`, config);
    },
    putUserPhoto: (
      userId: number,
      photo: File,
      config?: AxiosRequestConfig,
    ) => {
      const formData = new FormData();
      formData.append('photo', photo);
      return instance.put<{ photo: string }>(
        `/api-users/users/${userId}/photo/`,
        formData,
        config,
      );
    },
    putUserBio: (
      userId: number,
      bio: string,
      github: string,
      facebook: string,
      username: string,
      config?: AxiosRequestConfig,
    ) => {
      const data = {
        bio,
        github,
        facebook,
        username,
      };
      return instance.put<User.TProfile>(
        `/api-users/update/${userId}`,
        data,
        config,
      );
    },
    postUserChallenge: (
      userId: number,
      title: string,
      abstract: string,
      start_time: string,
      end_time: string,
      proj_url: string,
      config?: AxiosRequestConfig,
    ) => {
      const data = {
        title,
        abstract,
        start_time,
        end_time,
        proj_url,
        user: userId,
      };
      return instance.post(`/api-users/challenges/`, data, config);
    },
    signup: (signupForm: User.TSignUpForm, config?: AxiosRequestConfig) => {
      return instanceWOT.post(`/api-users/signup/`, signupForm, config);
    },
    fetchIsUserSignedUp: (email: string, config?: AxiosRequestConfig) => {
      return instanceWOT.get(`/api-users/signup/`, {
        params: { email },
        ...config,
      });
    },
    postToggleCloudUseSetting: () => {
      return instance.post('/api-users/status/change_use_cloud/');
    },
    // 로그인 시 온라인 로컬 스쿨 정보 조회
    fetchLocalSchoolList: () => {
      return instance.get('/localschool/');
    },
    sendCodeForPasswordReset: (email: string) => {
      const data = new FormData();
      data.append('email', email);
      return instanceWOT.post('/account/password_reset/', data);
    },
    resetPassword: (data: Http.Request.TResetPassword) => {
      const formData = new FormData();
      formData.append('token', data.token);
      formData.append('password', data.password);
      return instanceWOT.post('/account/password_reset/confirm/', formData);
    },
  },
  // #endregion
  /**************************************
   *! AUTH SMS
   *************************************/
  // #region
  authSms: {
    fetchAttendance: (config?: AxiosRequestConfig) => {
      return instance.get<{ check_attendance: boolean }>('/auth/attendance/');
    },
    postSms: (tel: string, config?: AxiosRequestConfig) => {
      const formData = new FormData();
      formData.append('phone_number', tel);
      return instance.post('/auth/', formData, config);
    },
    postValidcodeSignin(validcode: string, config?: AxiosRequestConfig) {
      const formData = new FormData();
      formData.append('validcode', validcode);
      return axios.post<{ token: string }>(
        '/auth/validcode/signin/',
        formData,
        {
          ...config,
          baseURL: API_HOST,
        },
      );
    },
    fetchVerify(
      tel: string,
      verificationCode: string,
      config?: AxiosRequestConfig,
    ) {
      return instance.get(`/auth`, {
        params: {
          phone_number: tel,
          auth_number: verificationCode,
        },
        ...config,
      });
    },
    fetchPassCheckAttendance(
      nodeProgressId: number | string,
      config?: AxiosRequestConfig,
    ) {
      return instance.get<{ check_attendance: boolean }>(
        `/auth/pass/check_attendance/${nodeProgressId}`, //TODO 현묵님과 같이 RESTful 하게 바꿀때 언더바 같이 수정 요청하기.
      );
    },
    postPassCertification(
      impUid: string,
      nodeProgressId: number | string,
      errorCode: string | null,
      config?: AxiosRequestConfig,
    ) {
      const formData = new FormData();
      formData.append('imp_uid', impUid);
      formData.append('nodeprogrs_id', nodeProgressId.toString());

      if (errorCode) {
        formData.append('error_code', errorCode);
      }

      return instance.post<TUserDataWithImpUid>(
        `/auth/pass/certification`,
        formData,
        config,
      );
    },
  },
  // #endregion
  /**************************************
   *! CONTENT
   *************************************/
  // #region
  content: {
    fetchContent: (slug: string, config?: AxiosRequestConfig) => {
      return instance.get<Sample.TContentResponse>(`/content/${slug}/`, config);
    },
    fetchSample: (slug: string, config?: AxiosRequestConfig) => {
      return instanceWOT.get<Sample.TContentResponse>(
        `/content/sample/${slug}/`,
        config,
      );
    },
  },
  // #endregion
  /**************************************
   *! ORDER
   *************************************/
  // #region
  order: {
    postOrder: (
      courseId: number | string,
      roundId: number | string,
      config?: AxiosRequestConfig,
    ) => {
      let formData = new FormData();
      formData.append('course_id', courseId.toString());
      formData.append('round_schedule_id', roundId.toString());
      return instance.post<{ order_id: number }>(
        '/order/create/',
        formData,
        config,
      );
    },
    postCheckout(orderId: number, amount: number, config?: AxiosRequestConfig) {
      let formData = new FormData();
      formData.append('order_id', orderId.toString());
      formData.append('amount', amount.toString());
      return instance.post<{ merchant_id: string }>(
        '/order/checkout/',
        formData,
        config,
      );
    },
    postCheckValidation(
      params: {
        orderId: number;
        merchantId: string;
        amount: number;
        impUid: string;
      },
      config?: AxiosRequestConfig,
    ) {
      let formData = new FormData();
      formData.append('order_id', params.orderId.toString());
      formData.append('merchant_id', params.merchantId);
      formData.append('amount', params.amount.toString());
      formData.append('imp_id', params.impUid);
      return instance.post('/order/validation/', formData, config);
    },
    fetchOrderDetail(orderId: number | string, config?: AxiosRequestConfig) {
      return instance.get<{
        course: string; // 확인 필요
        price: number;
        image: string;
        round_schedule: {
          start_date: string;
          end_date: string;
          round_number: number;
        };
        meta_description: string;
      }>(`/order/complete/${orderId}`, {
        ...config,
      });
    },
  },
  // #endregion
  /**************************************
   *! COMCOM
   *************************************/
  // #region
  comcom: {
    /**
     * User의 container 상태 fetch.
     * error response가 존재할 경우 에러 타입은 {detail:string}
     */
    fetchUserStatus(config?: AxiosRequestConfig) {
      return instance.post<Comcom.TUserContainerStatus>(
        `/api-comcom/getUserStatus/`,
        undefined,
        config,
      );
    },
    postRunContainer(
      nodeScheduleId: number | string,
      maxDuration: number | undefined,
      use_credit: boolean = false, //TODO: runContainer 호출시에도 use_credit 파라미터 추가해도 되는지 확인 필요 - 창덕
      config?: AxiosRequestConfig,
    ) {
      let formData = new FormData();
      formData.append('node_schedule', nodeScheduleId.toString());
      formData.append('use_credit', use_credit.toString());

      if (maxDuration !== undefined) {
        formData.append('max_duration', maxDuration.toString());
      }

      return instance.post<Comcom.TRunContainerResponse>(
        `/api-comcom/runContainerV2/`,
        formData,
        config,
      );
    },

    /**
     * Container 변경 또는 세션 연장 API
     * @param nodeScheduleId undefined일 경우 세션을 연장한다
     * @param use_credit
     * @param config
     * @returns
     */

    postEditContainer(
      nodeScheduleId: number | string | undefined,
      maxDuration: number | undefined,
      use_credit: boolean = false,
      config: AxiosRequestConfig | undefined = undefined,
    ) {
      let formData = new FormData();
      if (nodeScheduleId !== undefined) {
        formData.append('node_schedule', nodeScheduleId.toString());
      }
      formData.append('use_credit', use_credit.toString());
      return instance.post<Comcom.TRunContainerResponse>(
        `/api-comcom/editContainer/`,
        formData,
        config,
      );
    },
    postTerminateContainerWithEmail(
      userEmail: string,
      config?: AxiosRequestConfig,
    ) {
      let formData = new FormData();
      formData.append('user_email', userEmail);
      return instance.post(
        `api-comcom/terminateContainerPostman/`,
        formData,
        config,
      );
    },
    postTerminateContainer(config?: AxiosRequestConfig) {
      return instance.post<{ data: string }>(
        `api-comcom/terminateContainer/`,
        config,
      );
    },
    fetchAllContainerStatus() {
      return instance.post(`api-comcom/getAllUserStatus/`);
    },
    fetchContainerStatusById(schoolId: number) {
      let formData = new FormData();
      formData.append('localschool_id', schoolId.toString());
      return instance.post(`api-comcom/getAllUserStatus/`, formData);
    },
  },
  // #endregion
  /**************************************
   *! COACH
   *************************************/
  // #region
  coach: {
    fetchCoachTodayIssues: () => {
      return instance.get<TCoachToday>(`/api-evaluation/issues/`);
    },

    fetchSubmittedList: (nodeId: number, courseId: number) => {
      const params = new URLSearchParams();
      params.append('stage_id', courseId.toString());
      return instance.get<TNodeV1[]>(`/api-evaluation/${nodeId}`, { params });
    },

    postUserEval: (
      nodeProgressId: number,
      ratingArray: boolean[],
      message: string,
    ) => {
      const formData = new FormData();
      formData.append('node_progrs_id', nodeProgressId.toString());
      ratingArray.forEach((rate, index) => {
        formData.append(`rubric_${index + 1}_score`, rate === true ? '1' : '0');
      });

      formData.append('comment', message);
      return instance.post(`/api-evaluation/updateUserEval/`, formData);
    },
    postUpdateUserEval: (
      nodeProgressId: number,
      ratingArray: boolean[],
      message: string,
    ) => {
      const formData = new FormData();
      formData.append('node_progrs_id', nodeProgressId.toString());
      ratingArray.forEach((rate, index) => {
        formData.append(`rubric_${index + 1}_score`, rate === true ? '1' : '0');
      });

      formData.append('comment', message);
      return instance.post(`/api-evaluation/reUpdateUserEval/`, formData);
    },
  },
  // #endregion

  /**************************************
   *! CREDIT
   *************************************/
  //#region
  credit: {
    fetchTotal: () => {
      return instance.get<Http.Response.IGetCreditInfo>('/credit/total/');
    },
    postUsage(action: 'earn' | 'use', amount: number, nodeVersionId: number) {
      //{action: 2 (1: 적립, 2: 사용), amount: number (사용갯수), transaction_key: number (노드버전 pk)}
      return instance.post<Http.Response.IPostCreditUsage>('/credit/usage/', {
        action: action === 'earn' ? 1 : 2,
        amount,
        transaction_key: nodeVersionId,
      });
    },
  },
  //#endregion
  /**************************************
   *! DASHBOARD
   *************************************/
  // #region
  dashboard: {
    fetchLocalSchool() {
      return instance.get<TGetLocalSchool>('/api-dashboard/localschool/');
    },
    fetchCourse() {
      return instance.get<TGetCourse[]>(`/api-dashboard/course/`);
    },
    fetchEnrol() {
      return instance.get<TGetEnroll[]>(`/api-dashboard/enrol/`);
    },
    fetchNodeSchedule(searchFields?: string, search?: string, page?: number) {
      const params = new URLSearchParams();
      params.append('search_fields', searchFields ?? 'enrol__id');
      params.append('search', search ?? '');

      if (page) {
        params.append('page', page.toString());
      }

      return instance.get<TGetNodeSchedule>(`/api-dashboard/nodeschedule/`, {
        params,
      });
    },
  },
  // #endregion
  /**************************************
   *! FORUM
   *************************************/
  // #region
  forum: {
    fetchHelp: (params: any) => {
      return instance.get(`/api-forum/help/`, { params });
    },
  },
  // #endregion
  /**************************************
   *! SURVEY
   *************************************/
  // #region
  survey: {
    fetchSurvey(nodeVersionId: number, surveyId: number = 1) {
      let params = new URLSearchParams();
      params.append('node_version_id', nodeVersionId.toString());
      return instance.get<Survey>(`/survey/${surveyId}/`, { params });
    },
    postSurvey(nodeVersionId: number, answers: string[], surveyId: number = 1) {
      let formData = new FormData();
      formData.append('node_version_id', nodeVersionId.toString());
      for (let i = 0, l = answers.length; i < l; i++) {
        formData.append(`answer${i + 1}`, answers[i]);
      }
      return instance.post(`/survey/updateNode/${surveyId}/`, formData);
    },
  },
  /**************************************
   *! NOTIFICATION
   *************************************/
  // #region
  notification: {
    fetchAllList(postsPerPage: number = 10, config?: AxiosRequestConfig) {
      return instance.get<Notification.TNotificationListAllResponse>(
        `/notification/api/all_list/?max=${postsPerPage.toString()}`,
      );
    },
    postRead(slug: string) {
      return instance.post(
        `/notification/mark-as-read/${JSON.stringify(slug)}/`,
      );
    },
    postMarkAllAsRead() {
      return instance.post('/notification/mark-all-as-read/');
    },
    fetchUnreadCount() {
      return instance.get<{ unread_count: number }>(
        '/notification/api/unread_count/',
      );
    },
  },
  // #endregion
};

// NOTE: 나의 발자취 v2
export type TCourseAndNodeV2 = {
  last_eval_np: TNodeProgressV2 | null;
  stage_list: TCourse[];
  stat_card: {
    avg_star_cnt: number;
    complete_cnt: number;
    study_hour: number;
    time_joined: string | Date;
  }[];
  // NOTE: 평가 대기중
  waiting_eval_nps: TNodeProgressV2[] | null;
};

export type TRubricWithScore = {
  rubric_metric: string;
  score: number;
};

export type TCompnpList = {
  count: number;
  next: null | string;
  previous: null | string;
  results: [] | TNodeProgressV2[];
};

export type TNodeType = {
  is_lecture: boolean;
  is_project: boolean;
  is_tutorial: boolean;
  is_aico: boolean;
};
export type TNodeVersion = {
  id: number;
};

export type TNodeInfo = {
  id: number;
  node_version: TNodeVersion;
  node_title: string;
  node_abstract: string;
  node_type: TNodeType;
  node_difficulty: string;
};

export type TUserEnrolment = {
  enrol: TEnroll;
  subject: {
    title: string;
  } | null;
};

export type TGuideNodeversion = {
  type: string;
  guide_nodeversion_id: number | null;
};

export type TEnroll = {
  course: TCourse;
  require_authenticate: boolean;
  submission_methods: string[];
};
export type TCourse = {
  id: number;
  title: string;
  color: string;
  course_type: TCourseType;
};
export type TCourseType = {
  id: number;
  type: string;
};

// TODO: "민수님" 여기가 강의상태 타입 부분입니다. 확인후 해당 주석 삭제해주세요.
export const NodeStatus = {
  /**  진행전 **/
  A: 'A',
  /**  오늘할것 **/
  B: 'B',
  /**  진행중 **/
  C: 'C',
  /**  lecture 노드 완료 **/
  D: 'D',
  /**  project 노드 완료 **/
  E: 'E',
  /**  staff only **/
  F: 'F',
  /**  project 노드 평가완료 **/
  G: 'G',
  /**  지연 (노드스케쥴 종료일 이후 n일 경과) **/
  H: 'H',
} as const;
export type TNodeStatus = typeof NodeStatus[keyof typeof NodeStatus];

export const CourseStatus = {
  /** 결제완료 (미승인상태) **/
  Z: 'Z',
  /** 진행전 (승인상태) **/
  A: 'A',
  /** 진행가능 (회차시작일 되면)` (z, a 만 이걸로 갈 수 있음)** **/
  B: 'B',
  /** 진행중 (active course progrs로 상태변경)**` **/
  C: 'C',
  /** 관리자 거부 **/
  D: 'D',
  /** 환불처리 **/
  F: 'F',
  /** 수강완료 (전체진행률 80% 도달시)**` **/
  G: 'G',
  /** 수료증 출력가능 (회차종료일 이후) **/
  H: 'H',
  /** 지연 (회차종료일까지 B,C) **/
  I: 'I',
  /** 심각한 지연 (I에서 미접속 n일) **/
  J: 'J',
  /** 스태프, **/
  S: 'S',
} as const;
export type TCourseStatus = typeof CourseStatus[keyof typeof CourseStatus];

export interface INode {
  id: number;
  status: string;
  node_schedule: TNodeInfo;
  progrs_pct: number; // NOTE: swagger 에는 string 실제론 넘버 ...

  user_enrolments: TUserEnrolment;
  remaining_time: {
    D: number;
    H: number;
  } | null;
}

export type TNodeCard = {
  deadline_nps_cnt: number;
  overdue_nps_cnt: number;
  waiting_nps_cnt: number;
};

export interface IDeadlineNode extends INode {}
export interface ILatestNode extends INode {}

export type TTodayNode = {
  cnt_list: TNodeCard[];
  deadline_node_progrs: IDeadlineNode[];
  latest_node_progrs: ILatestNode | null;
};

export type TWaitNPNodeList = {
  count: number;
  next: string | null;
  previous: string | null;
  results: INode[];
};

// #region KDC

export type TResponseClassroom = {
  latest_course_progrs: {
    id: number;
    course: string;
    course_progrs_pct: number;
    end_date: string | Date | null;
    image: string;
    latest: {
      id: number;
      node_schedule: TNodeInfo;
      progrs_pct: number;
      remaining_time: null | string | Date;
      status: TNodeStatus;
      user_enrolments: TUserEnrolment;
    };
    retake_status: number;
    status: TCourseStatus;
    start_date: string | Date | null;
    status_z_time: string | Date | null;
  };
  my_course_progrs: [] | TCourseProgress[];
};

export type TCourseProgress = {
  id: number;
  course: string;
  end_date: string | Date | null;
  image: string;
  round_schedule: {
    id: number;
    end_date: string | Date | null;
    is_available: boolean;
    round_number: number;
    start_date: string | Date | null;
  };
  start_date: string | Date | null;
  status: TCourseStatus;
  status_z_time: string | Date | null;
};
// #endregion

// #region V1 API
export type TCourseAndNodeV1 = {
  node_list: TNodeV1[];
  stage_list: TStageV1[];
};
export type TNodeV1 = {
  abstract: string;
  closed_cnt: number;
  compt_step_cnt: number;
  course_id: number;
  course_title: string;
  course_type: number;
  id: number;
  is_lecture: boolean;
  is_project: boolean;
  is_tutorial: boolean;
  open_cnt: number;
  node_progrs: TNodeProgress;
  rubric: TRubric[] | [];
  title: string;
  total_step_cnt: number;
  user_submission: TUserSubmission[] | null;
};

export type TStageV1 = {
  color: string;
  course_type: number;
  id: number;
  local_school: number;
  title: string;
};

export type TNodeProgress = {
  id: number;
  coach: number;
  evaluation: TEvaluation[] | [];
  node_schedule: number;
  remaining_time: null | {
    D: number;
    H: number;
  };
  status: TNodeStatus;
  status_c_time: null | string;
  status_d_time: null | string;
  status_e_time: null | string;
  status_f_time: null | string;
  user_enrolments: number;
  user_id: number;
};

export type TNodeProgressV2 = {
  id: number;
  user_enrolments: TUserEnrolment;
  node_schedule: TNodeInfo;
  status: TNodeStatus;
  status_d_time?: string | Date | null;
  status_e_time?: string | Date | null;
  coach_username?: string;
  evaluation: [] | TRubricWithScore[];
  // TODO: 정확한 타입 찾아야함.
  sum_score?: unknown;
};

export type TNodeV1Filter = {
  condition?: 'ACHIVED' | 'NOTACHIVED';
  stageId?: number;
};

export type TCoachToday = {
  node_list: TNodeV1[];
  stage_list: TStageV1[];
  today_node: TNodeV1[]; // TODO: 타입 확실한 타입으로 보강해야함.
  today_stage: TStageV1[]; // TODO: 타입 확실한 타입으로 보강해야함.
};

export type TRubric = {
  metric: string;
  node: number;
  order: number;
  rubric_category: string[];
};

// #endregion

export type TEvaluation = {
  rubric: number;
  score: number;
  node_progrs: number;
};

// #region coach Rubric user submitList
export type TUserSubmission = {
  id: number;
  node: number;
  submit_block_id: string;
  submit_data: string;
  submit_time: string;
  submit_type: string | 'Proj';
  user: number;
  user_username: string;
};

export type TSubmitData = {
  progrsData: TNodeProgress;
  submissionData: TUserSubmission;
};

// #endregion

export type TPageResponse<T> = {
  count: number;
  /** 다음 페이지 api url */
  next: string | null;
  /** 이전 페이지 api url */
  previous: string | null;
  results: T[];
};
export type Nullable<T> = T | null;

// #region Dashboard Cloud
export type TLocalSchoolInfoResponse = {
  localschoolInfo: ILocalSchoolInfo[];
};

// export type TLocalSchoolInfoResponseById = {
//   ...ILocalSchoolInfo
// }
export type ILocalSchoolInfo = {
  clusterName: string;
  currentUserCnt: number;
  currentWorkspaceCnt: number;
  localschoolId: number;
  localschoolName: string;
  machineInfo: {
    cpu: ICloudInfo;
    gpu: ICloudInfo;
  };
  maxUserCnt: 190;
  users: ICloudUsageUser[];
  workerAddress?: string;
};

export interface ICloudInfo {
  baseImagePath: string;
  currentCnt: number;
  maxNodeCnt: number;
  nodeCnt: number;
  readyNodeCnt: number;
}

export interface ICloudUsageUser {
  localschoolId: number;
  usageWorkspace: any;
  useWorkspaceInfo?: ICloudUserWorkSpace;
  userId: string;
}

export interface ICloudUserWorkSpace {
  aiffelNodeId: string;
  clusterName: string;
  createdAt: number;
  endpoint: {
    jupyter: string;
    shell: string;
  };
  imagePath: string;
  localschoolId: number;
  machineType: string;
  maxDurationMinute: number;
  namespaceId: string;
  processing: boolean;
  remainingTimeMinute: number;
  removeTaskId: string;
  sharedStorageId: string;
  startedAt: number;
  status: string; // 'running' , notReady
  storagePath: string;
  timerStartedAt: number;
  userId: string;
  userStorageId: string;
  workerAddress: string;
  workspaceId: string;
  containerNode?: string;
}

// #endregion

// #region Dashboard Course

export type TGetLocalSchool = {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: TLocalSchool[] | [];
};
export type TLocalSchool = {
  id: number;
  title: string;
  abstract: string;
  country: string | 'KR';
  check_ip: boolean;
  check_mac_addr: boolean;
  city: string | 'Asia/Seoul';
  public: boolean;
};

export interface ICourseInClassroom {
  course: string;
  id: number;
  latest?: any; // TODO: 추후에 다시 명세
  remaining_day: number;
  round_schedule: {
    id: number;
    round_number: number;
    start_date: string;
    end_date: string;
    is_available: boolean;
  };
  status: string;
}

export interface TGetCourse {
  id: number;
  title: string;
  course_type: number;
  color: string;
  local_school: number;
}

export interface TGetEnroll {
  id: number;
  enrol_start_date: string;
  enrol_end_date: string;
  is_schedule: boolean;
  description: string;
  service_type: TServiceType;
  course: number;
}

export type TServiceType = 'A' | 'B' | 'C';

export type TGetNodeSchedule = {
  count: number;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: TNodeSchedule[] | [];
};

export type TNodeSchedule = {
  enrol: number;
  id: number;
  node_title: string;
  start_time: string;
  end_time: string;
};

// #endregion
